import { useEffect } from "react";
import { navigate } from "@reach/router";

const NotFound = (props) => {
  //   console.log(props);
  //   useEffect(() => {
  //     navigate("/hk");
  //   }, []);
  return null;
};

export default NotFound;
